import React from "react";

import {Col, Container, Row} from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import Statistics from "../../components/Statistics";
import Questions from "../../components/Questions";
import WeAreCTA from "../../components/WeAreCTA";
import Button from "../../components/Button";
import MotifColumn from "../../components/MotifColumn"

import styles from "../alberta/index.module.scss";
import GatedContentForm from "../../components/GatedContentForm";
import Services from "../../components/OurServices";


const WhitePaper = () => {
    return (
        <Layout>
            <Background themeType={"oanda"}>
                <Hero backgroundType={"dark"} type={"alberta"}>
                    <MotifColumn>
                        <h1>Helping Your Business Grow Global</h1>
                        <p>If you’d like to learn more about how foreign exchange impacts your business, download our
                            FREE white paper on managing forex risk to learn about your vulnerabilities, risk management
                            strategies, and more. Shift Connect is proud to serve our global community. From simple
                            global money transfers to in-depth foreign exchange strategies, we’ve got you covered. If
                            you’re ready to partner with Shift Connect for expert support, get in touch today. We’re
                            here for you.
                        </p>
                    </MotifColumn>
                    <GatedContentForm/>
                </Hero>
                <Container>
                    <Row class="row full-width-row">
                        <Col className={styles.firstSection} xs={12}>
                            <h1>Welcome to the Global Community. </h1>
                            <p>
                                Shift offers unparalleled customer service and responsiveness. We are an agile company
                                that prioritizes customer experience and satisfaction. We understand the pain points of
                                trying to move currencies cross-border and are always striving to alleviate this
                                challenge for our clients. Learn more about our services below. Enough about us, lets
                                talk about you. <br/><br/>

                                You can’t understand where you’re going without taking a look at where you’re at. Let’s
                                kick this off with a 15-minute forex assessment so our experts can identify your needs,
                                goals, and the best path forward. Your custom forex solution starts here – let’s chat.
                            </p>
                            <Button
                                href='https://outlook.office365.com/owa/calendar/IntroCall@shiftconnect.com/bookings/'
                                className={styles.button}
                                color={"purple"}
                            >
                                Book FX Assessment
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Services/>
                <WeAreCTA/>
                <Statistics themeType={"dark"}/>
                <Questions/>
            </Background>
        </Layout>
    );
};

export default (WhitePaper);
